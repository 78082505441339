<template>
	<div
		v-if="type == 'ADDEXTENSION' || (type == 'NEW' && this.$store.getters.getBasicItemSelected.length > 0)"
		class="section_aside"
		v-bind:style="{ top: computedTop }"
	>
		<!--@order start-->
		<!-- <div class="wrap_aside"> -->
		<div>
			<div class="aside_header" v-if="this.$store.getters.getBasicItemSelected.length > 0">
				<!--membership-->
				<span class="tit">Total</span>
				<h3>
					{{ serviceNameDisplay(this.$store.getters.getBasicItemSelected[0]) }}
					<!--<span class="txt_s">{{
						$constants.serviceTypeDisplay[
							this.$store.getters.getBasicItemSelected[0].serviceCategoryModel
								.serviceType
						]
					}}</span>-->
				</h3>
				<span class="com_tag">
					<span class="txt" v-if="this.$store.getters.getBasicItemSelected[0].usePeriod > 0"
						>{{ shorteningWords(this.$store.getters.getBasicItemSelected[0].usePeriod) }} 개월</span
					>
					<span class="txt" v-if="this.$store.getters.getBasicItemSelected[0].userCnt > 0"
						>사용자 {{ this.$store.getters.getBasicItemSelected[0].userCnt }}인</span
					>
					<span class="txt" v-if="this.$store.getters.getTotQuota > 0"
						>용량 {{ this.$store.getters.getTotQuota }}GB</span
					>
				</span>
				<!--//membership-->
			</div>

			<!--@scroll area-->
			<div class="aside_body">
				<!--@order item-->
				<div class="wrap_box" v-for="item in this.$store.getters.getBasicItemSelected" :key="item.cd">
					<table v-if="item.usePeriod > 0" class="com_tb_metadata tb_order">
						<tbody>
							<tr v-if="item.defaultFeePolicyModel.usePolicy">
								<td class="tit">
									<span class="txt">{{ item.usePeriod }} 개월 기본료</span>
								</td>
								<td class="amount">
									<span class="txt"
										>{{ item.defaultFeePolicyModel.defaultUserNum }}명 /
										{{ item.defaultFeePolicyModel.defaultVolume }}GB</span
									>
								</td>
								<td class="price">
									<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
								</td>
								<td
									class="more"
									v-if="item.defaultFeeTotDiscount < 0"
									v-on:click="toggleFold('base', 'defaultFee', item.id)"
								>
									<button v-if="item.defaultFeeDetailFold == false" class="fold" title="접기">
										<i class="ic_set ic_arrow_t"></i>
									</button>
									<button v-if="item.defaultFeeDetailFold == true" class="unfold" title="펼치기">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>
							<tr v-if="item.defaultFeeDetailFold == false">
								<td colspan="4">
									<table class="com_tb_metadata tb_detail">
										<tbody>
											<tr>
												<td class="tit">
													<span class="txt">단가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeePolicyModel.price | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">사용기간</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.usePeriod }} 개월 </span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">정가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotNormalPrice | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">할인 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotDiscount | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">최종 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr v-if="item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0">
								<td class="tit"><span class="txt">사용자 추가</span></td>
								<td class="amount">
									<span class="txt"
										>{{ item.addUserCnt }}명
										<template
											v-if="getTotQuota(item.addUserCnt, item.addUserIntervalPolicyModel.defaultVolumeForAddUser) > 0"
										>
											/
											{{ getTotQuota(item.addUserCnt, item.addUserIntervalPolicyModel.defaultVolumeForAddUser) }}GB
										</template></span
									>
								</td>
								<td class="price">
									<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								v-if="
									item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0 && item.addUserTotNormalPrice > 0
								"
								class="mini"
							>
								<td class="tit"></td>
								<td class="amount"></td>
								<td class="price">
									<span class="txt"
										><del>{{ item.addUserTotNormalPrice | currency }}원</del></span
									><button class="unfold" title="펼치기" v-on:click="openModal(item)">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeePolicyModel.usePolicy">
								<td class="tit"><span class="txt">설치비</span></td>
								<!-- 해당 부분에 value(1개)는 원복 필요할 수도 있음 -->
								<td class="amount"><span class="txt">1개</span></td>
								<td class="price">
									<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
								</td>
								<td
									class="more"
									v-if="item.membershipFeeDiscount < 0"
									v-on:click="toggleFold('base', 'membershipFee', item.id)"
								>
									<button v-if="item.membershipFeeDetailFold == false" class="fold" title="접기">
										<i class="ic_set ic_arrow_t"></i>
									</button>
									<button v-if="item.membershipFeeDetailFold == true" class="unfold" title="펼치기">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeeDetailFold == false">
								<td colspan="4">
									<table class="com_tb_metadata tb_detail">
										<tbody>
											<tr>
												<td class="tit">
													<span class="txt">정가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeNormalPrice | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">할인 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeDiscount | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">최종 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr v-for="row in item.optionItemSelected" :key="row.id">
								<td class="tit">
									<span class="txt">{{ shorteningWords(row.displaySectionModel.displayName) }}</span>
								</td>
								<td class="amount">
									<span class="txt">{{ row.orderCount }}개</span>
								</td>
								<td class="price">
									<span class="txt">{{ row.finalPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--//order item-->

				<!--@order item-->
				<div class="wrap_box" v-for="(item, index) in this.$store.getters.getAddonItemSelected" :key="index">
					<div class="box_header">
						<span v-if="item.name.indexOf('경리회계') > -1" class="ic_svc ic_accounting_s"></span>
						<span v-if="item.name == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
						<span v-if="item.name == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
						<span v-if="item.name == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
						<span v-if="item.name == 'TMSe 장비일체형 T'" class="ic_svc ic_tmse_share"></span>
						<span class="tit">{{ item.name }}</span>
						<span v-if="item.freeTrialPolicyModel.usePolicy == true" class="com_tag_badge">
							<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span>
						</span>
					</div>
					<table class="com_tb_metadata tb_order">
						<tbody>
							<tr v-if="item.defaultFeePolicyModel.usePolicy">
								<td class="tit" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
									<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }} 개월 기본료</span>
								</td>
								<td class="tit" v-else>
									<span class="txt">{{ item.usePeriod }} 개월 기본료</span>
								</td>
								<td class="amount">
									<span class="txt">{{ item.defaultFeePolicyModel.defaultUserNum }}명</span>
								</td>
								<td class="price">
									<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
								</td>
								<td
									class="more"
									v-if="item.defaultFeeTotDiscount > 0"
									v-on:click="toggleFold('addon', 'defaultFee', item.id)"
								>
									<button v-if="item.defaultFeeDetailFold == false" class="fold" title="접기">
										<i class="ic_set ic_arrow_t"></i>
									</button>
									<button v-if="item.defaultFeeDetailFold == true" class="unfold" title="펼치기">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>

							<tr v-if="item.defaultFeeDetailFold == false">
								<td colspan="4">
									<table class="com_tb_metadata tb_detail">
										<tbody>
											<tr>
												<td class="tit">
													<span class="txt">단가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeePolicyModel.price | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">사용기간</span>
												</td>
												<td class="amount"></td>

												<td class="price" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
													<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }} 개월 </span>
												</td>
												<td class="price" v-else>
													<span class="txt">{{ item.usePeriod }} 개월 </span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">정가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotNormalPrice | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">할인 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotDiscount | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">최종 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr v-if="item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0">
								<td class="tit"><span class="txt">사용자 추가</span></td>
								<td class="amount">
									<span class="txt">{{ item.addUserCnt }}명</span>
								</td>
								<td class="price">
									<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								v-if="
									item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0 && item.addUserTotNormalPrice > 0
								"
								class="mini"
							>
								<td class="tit"></td>
								<td class="amount"></td>
								<td class="price">
									<span class="txt"
										><del>{{ item.addUserTotNormalPrice | currency }}원</del></span
									><button class="unfold" title="펼치기" v-on:click="openModal(item)">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeePolicyModel.usePolicy">
								<td class="tit"><span class="txt">설치비</span></td>
								<!-- 해당 부분에 value(1개)는 원복 필요할 수도 있음 -->
								<td class="amount"><span class="txt">1개</span></td>
								<td class="price">
									<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
								</td>
								<td
									class="more"
									v-if="item.membershipFeeDiscount < 0"
									v-on:click="toggleFold('addon', 'membershipFee', item.id)"
								>
									<button v-if="item.membershipFeeDetailFold == false" class="fold" title="접기">
										<i class="ic_set ic_arrow_t"></i>
									</button>
									<button v-if="item.membershipFeeDetailFold == true" class="unfold" title="펼치기">
										<i class="ic_set ic_arrow_b"></i>
									</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeeDetailFold == false">
								<td colspan="4">
									<table class="com_tb_metadata tb_detail">
										<tbody>
											<tr>
												<td class="tit">
													<span class="txt">정가</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeNormalPrice | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">할인 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeDiscount | currency }}원</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">최종 금액</span>
												</td>
												<td class="amount"></td>
												<td class="price">
													<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr v-for="row in item.optionItemSelected" :key="row.id">
								<td class="tit">
									<span class="txt">{{ shorteningWords(row.displaySectionModel.displayName) }}</span>
								</td>
								<td class="amount">
									<span class="txt">{{ row.orderCount }}개</span>
								</td>
								<td class="price">
									<span class="txt">{{ row.finalPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--//order item-->
			</div>
			<!--//scroll area-->

			<!--@position :fixed bottom area-->
			<div class="aside_footer">
				<!--@order summary-->
				<div class="wrap_summary" v-if="this.$store.getters.getBasicItemSelected.length > 0">
					<table class="com_tb_metadata tb_order_total" style="display: none">
						<tbody>
							<tr>
								<td class="tit"><span class="txt">상품 금액</span></td>
								<td class="data">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totOriginPrice | currency }}원</span>
								</td>
							</tr>
							<tr class="emphasise">
								<td class="tit">
									<span class="txt">할인 금액</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip right">
												12개월 이상 선납 할인 또는 프로모션으로 혜택 받은 금액입니다. <i class="tail_right"></i
											></span>
										</span>
									</span>
								</td>
								<td class="data">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="tit">
									<span class="txt">상품 금액 - 할인 금액</span>
								</td>
								<td class="data">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totPayPrice | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="tit"><span class="txt">부가세(10%)</span></td>
								<td class="data">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.surtaxPrice | currency }}원</span>
								</td>
							</tr>
							<tr class="total">
								<td class="tit">
									<span class="txt">결제 금액(VAT포함)</span>
								</td>
								<td class="data">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.finalPayPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="aside_action">
						<template v-if="type == 'NEW'">
							<a class="major large flex" v-on:click="applyEstimate()">
								<span class="txt">견적서 받기</span>
							</a>
							<a class="minor large flex" v-on:click="applyService()">
								<span class="txt">회원가입 후 서비스 신청하기</span>
							</a>
						</template>
					</div>
					<div class="aside_message" v-if="serviceType === 'VPC'">
						<i class="ic_set ic_caution"></i>
						<p>
							단독형은 온라인 주문이 불가능합니다. <br />
							추가/연장 주문이 필요하실 경우 다우오피스 영업 담당자에게 문의하시기 바랍니다. <br />
							1577-3019(내선 2번)
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { product } from '@/views/mixinsProduct.js';
export default {
	mixins: [product],
	data() {
		return {
			baseFoldFlag: {},
			addonFoldFlag: {},
			columns: ['구간', '가격', '할인수', '할인', '할인 금액'],
			top: '50px',
			showModal: false,
		};
	},
	props: {
		type: {
			type: String,
			default: 'NEW',
		},
		usingMembership: {
			type: Object,
			default: null,
		},
		serviceType: {
			type: String,
			default: 'SAAS',
		},
	},
	computed: {
		computedTop: function () {
			if (this.type == 'NEW') {
				require('@/assets/css/doms_dev_custom.css');
			}
			return this.top;
		},
	},
	methods: {
		getTotQuota(addUserCnt, unitQuota) {
			// 상단 노출될 용량 계산
			if (!addUserCnt) {
				return 0;
			}
			if (!unitQuota) {
				return 0;
			}
			return parseInt(addUserCnt) * parseInt(unitQuota);
		},
		shorteningWords(str) {
			// 상품명 줄임 처리(10자 이상 말줄임)
			if (str.length > 20) {
				return str.substr(0, 10) + '...';
			}
			return str;
		},
		async checkVirtualDomain(domain) {
			// 가상 도메인 유효성체크
			// 1. 길이 조건 체크
			if (domain.length < 3 || domain.length > 64) {
				return '길이 조건(3자리 이상 64자리 이하) 미충족';
			}
			// 2. 문자열 체크
			var domainTest = domain;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(domainTest.replace(/[\\_.-]/g, ''))) {
				return '유효하지 않는 형식';
			}
			// 3. 중복/사용불가문자 등 API 조회 체크
			const response = await this.$store.dispatch('CHECKDOMAIN', domain);
			return response.data ? response.data : response.errorDescription;
		},
		toggleFold(type, typeDtl, itemId) {
			// 영역접기/펼치기
			this.$store.commit('toggleFold', {
				type: type, // base, addon
				typeDtl: typeDtl, // defaultFee, membershipFee, addUserFee
				itemId: itemId, // (부가일때)아이디
			});
		},
		openModal(item) {
			// 사용자 구간 모달 OPEN
			this.$emit('openModal', item);
		},
		async applyService() {
			window.open(`${process.env.VUE_APP_CUSTOMER_PORTAL_URL}` + 'signup');
		},
		applyEstimate() {
			if (
				this.$store.getters.getBasicItemSelected[0].userCnt <
				this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum
			) {
				this.$ms.dialog.alert(
					'최소 사용자 수 제한',
					'사용자수는 최소 ' +
						this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum +
						'명 이상 입력하셔야 합니다.',
				);
				return;
			}
			// [견적서 받기] 선택
			this.$router.push(`/apply/estimate/step1`);
		},
	},
	components: {},
};
</script>
