export const productClassifier = {
	methods: {
		// 경리회계 부가서비스 구분
		isAccountAddonChannel(item) {
			return item.name.indexOf(this.$constants.ACCOUNTING) > -1;
		},
		// 근태휴가 부가서비스 구분
		isAttendanceAddonChannel(item) {
			return item.name.indexOf(this.$constants.ATT) > -1;
		},
		// 필수 부가서비스 구분
		// 경리회계, 근태연차 부가서비스를 필수 부가서비스로 지정한다.
		isRequiredAddonChannel(item) {
			return this.isAccountAddonChannel(item) || this.isSaasAttendance(item);
		},
		// 공유형 근태연차 부가서비스 여부
		isSaasAttendance(item) {
			return this.isAttendanceAddonChannel(item) && item.serviceCategoryModel.serviceType === 'SAAS';
		},
	},
};
