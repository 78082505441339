export const calculate = {
	// 신규 신청 계산 methods(설치비, 기본료, 사용자 추가금)
	// 설치비(도입비) 원가, 할인가 setting
	methods: {
		async setMembershipFeeDiscountMatrix(item) {
			if (!item.membershipFeePolicyModel.usePolicy) {
				item['membershipFeeNormalPrice'] = 0;
				item['membershipFeeDiscountPrice'] = 0;
				item['membershipFeeDiscount'] = 0;
				return item;
			}
			// 프로모션
			let discountPolicy = null;
			let discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				// 프로모션 진행 X면 dummy데이터
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				discountPolicyIndex = item.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'MEMBERSHIP_FEE' && obj.useNew,
				);
				if (discountPolicyIndex > -1) {
					// 설치비 프로모션 정보
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
				} else {
					// 프로모션 진행 X면 dummy데이터
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}
			// 설치비
			item['membershipFeeNormalPrice'] = item.membershipFeePolicyModel.price;
			// 할인된 설치비
			item['membershipFeeDiscountPrice'] =
				parseInt(item.membershipFeePolicyModel.price) *
					(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) -
				(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0);
			item['membershipFeeDiscount'] = (item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice) * -1;

			if (discountPolicyIndex > -1) {
				if (item.membershipFeeNormalPrice > item.membershipFeeDiscountPrice) {
					// 할인금액(설치비 - 할인설치비)
					item.discountPolicyModels[discountPolicyIndex]['discountAmount'] =
						(item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice) * -1;
				}
			}
			return item;
		},
		// 기본료 원가, 할인가 setting
		async setDefaultFeeDiscountMatrix(item) {
			if (!item.defaultFeePolicyModel.usePolicy) {
				item['defaultFeeTotNormalPrice'] = 0;
				item['defaultFeeTotDiscountPrice'] = 0;
				item['defaultFeeTotDiscount'] = 0;
				return item;
			}

			let discountPolicy = null;
			let discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				discountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'DEFAULT_FEE' && obj.useNew);
				if (discountPolicyIndex > -1) {
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
				} else {
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}

			// 장기선납 프로모션
			let periodDiscount = 0;
			let periodPolicyIndex;
			if (item.periodPolicyModel.useLongTermPrepaymentDiscount) {
				let periodPolicyModelDiscountRate = item.periodPolicyModel.discountRate;
				periodPolicyIndex = periodPolicyModelDiscountRate.findIndex((obj) => obj.month == item.usePeriod);
				if (periodPolicyIndex > -1) {
					periodDiscount = periodPolicyModelDiscountRate[periodPolicyIndex].rate;
				}
			}
			// 결제 대상 기간
			let payPeriod = parseInt(item.usePeriod);
			if (item.freeTrialPolicyModel !== undefined && item.freeTrialPolicyModel.usePolicy) {
				// 무료체험은 무료체험 기간 제외
				payPeriod -= parseInt(item.freeTrialPolicyModel.usagePeriod);
			}

			// 기본료 계산 = 기본료 * 가격
			item['defaultFeeTotNormalPrice'] = item.defaultFeePolicyModel.price * payPeriod;
			// (프로모션,장기선납)할인된 기본료 = [{가격 * (100-RATE할인율)/100 * 사용기간} - AMOUNT할인금액] * (100-장기선납할인율)/100
			item['defaultFeeTotDiscountPrice'] =
				(parseInt(item.defaultFeePolicyModel.price) *
					(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) *
					payPeriod -
					(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0)) *
				((100 - periodDiscount) / 100);
			// 할인된 금액 = 기본료 - 할인된 기본료
			item['defaultFeeTotDiscount'] = (item.defaultFeeTotNormalPrice - item.defaultFeeTotDiscountPrice) * -1;

			if (discountPolicyIndex > -1) {
				// 기본료 프로모션 진행중일 때, 할인액 계산
				// 기본료 프로모션 할인액 = (-1) * {(가격 * RATE할인율/100 * 사용기간) + AMOUNT할인금액}
				item.discountPolicyModels[discountPolicyIndex]['discountAmount'] =
					-1 *
					(parseInt(item.defaultFeePolicyModel.price) *
						(discountPolicy.benefitType == 'RATE' ? discountPolicy.num / 100 : 0) *
						payPeriod +
						(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0));
			}

			if (periodPolicyIndex > -1) {
				// 장기선납 진행중일때, 할인액 계산
				// 장기선납 할인액 = (-1) * [{가격 * (100-RATE할인율)/100 * 사용기간} - AMOUNT할인금액] * 장기선납할인율/100
				let value =
					(parseInt(item.defaultFeePolicyModel.price) *
						(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) *
						payPeriod -
						(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0)) *
					(periodDiscount / 100);

				if (value > 0) {
					item.periodPolicyModel.discountAmount.push(-1 * value);
				}
			}

			return item;
		},
		// 사용자추가 원가, 할인가 setting
		async setAddUserDiscountMatrix(item) {
			// 사용자 구간별 계산 정보 담을 matrix 배열
			item['addUserDiscountMatrix'] = [];
			if (!item.addUserIntervalPolicyModel.usePolicy) {
				item['addUserTotNormalPrice'] = 0; //원가 합계
				item['addUserTotDiscountPrice'] = 0; //할인액 합계
				item['addUserTotDiscount'] = 0;
				return item;
			}
			// 기본 사용자 수
			const defaultCnt = parseInt(item.defaultFeePolicyModel.defaultUserNum);
			// 추가 사용자 수
			let addUserCnt = parseInt(item.addUserCnt);
			// 프로모션
			let discountPolicy = null;
			let discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				discountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'ADD_USER' && obj.useNew);
				if (discountPolicyIndex > -1) {
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
					item.discountPolicyModels[discountPolicyIndex]['discountAmount'] = 0;
				} else {
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}

			let promotionDiscount = item.promotionDiscount ? item.promotionDiscount : {};
			if (promotionDiscount.use) {
				item.discountPolicyModels.forEach((o) => {
					let discount = 0;
					if ('FREE' == item.chargeType) {
						discount = 100;
					} else if (item.usePeriod >= promotionDiscount.usePeriod) {
						discount = Number(promotionDiscount.discount);
						promotionDiscount.orderType.forEach((orderType) => {
							o[orderType] = true;
						});
					}

					if (promotionDiscount.itemType.includes(o.itemType)) {
						o.num = discount;
					}
				});
			}
			// 장기선납 할인
			let periodDiscount = 0;
			let periodPolicyIndex = -1;
			if (item.periodPolicyModel.useLongTermPrepaymentDiscount) {
				let periodPolicyModelDiscountRate = item.periodPolicyModel.discountRate;
				periodPolicyIndex = periodPolicyModelDiscountRate.findIndex((obj) => obj.month == item.usePeriod);
				if (periodPolicyIndex > -1) {
					periodDiscount = periodPolicyModelDiscountRate[periodPolicyIndex].rate;
				}
			}

			// 결제 대상 기간
			let payPeriod = parseInt(item.usePeriod);
			if (item.freeTrialPolicyModel !== undefined && item.freeTrialPolicyModel.usePolicy) {
				// 무료체험은 무료체험 기간 제외
				payPeriod -= parseInt(item.freeTrialPolicyModel.usagePeriod);
			}
			// 사용자 수 구간별 계산
			let addUserIntervals = item.addUserIntervalPolicyModel.addUserIntervals;
			// 할인 정보
			let discountInfo = '';
			if (discountPolicy && parseInt(discountPolicy.num) > 0) {
				discountInfo +=
					'<span>프로모션할인 <span class="em">' +
					discountPolicy.num +
					(discountPolicy.benefitType == 'AMOUNT' ? '원' : '%') +
					'</span></span><br>';
			}

			if (periodDiscount > 0) {
				discountInfo += '<span>장기선납할인 <span class="em">' + periodDiscount + '%</span></span>';
			}

			for (let idx in addUserIntervals) {
				let i = addUserIntervals[idx]; // 구간
				if (defaultCnt < i.max && addUserCnt > 0) {
					// 기본 데이터셋 설정
					const a = {
						addUserIntervals: addUserIntervals[idx],
						interval: i.min + '~' + i.max,
						price: i.price,
						code: i.code,
						discountCnt: 0, // 할인받은 사용자 수
						discountInfo: discountInfo,
						totPrice: 0,
						discountTotPrice: 0,
						originPrice: 0,
						discountPrice: 0,
						periodDiscountPrice: 0,
					};

					if (defaultCnt > i.min) {
						// 기본사용자 수가 포함된 구간 : 해당 구간 사용자 수 = 기본사용자 수 제외
						if (i.max - defaultCnt < addUserCnt) {
							a.discountCnt = i.max - defaultCnt;
							addUserCnt -= i.max - defaultCnt;
						} else {
							a.discountCnt = addUserCnt;
							addUserCnt -= addUserCnt;
						}
					} else if (addUserCnt <= i.max - i.min) {
						// 추가 사용자 수가 해당 구간에 모두 포함될 경우
						a.discountCnt = addUserCnt;
						addUserCnt -= addUserCnt;
					} else {
						if (i.min == 0) {
							a.discountCnt = i.max - i.min;
						} else {
							a.discountCnt = i.max - i.min + 1;
						}
						addUserCnt -= a.discountCnt;
					}
					a.originPrice = a.discountCnt * i.price * payPeriod; // 원가
					if (discountPolicy !== null && discountPolicy.benefitType == 'RATE' && discountPolicy.num > 0) {
						// (RATE)프로모션 할인적용 금액
						a.totPrice =
							a.discountCnt *
							i.price *
							(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) *
							payPeriod;
						// (RATE)프로모션 할인받은 금액
						a.discountPrice = a.originPrice - a.totPrice;
					} else if (discountPolicy !== null && discountPolicy.benefitType == 'AMOUNT' && discountPolicy.num > 0) {
						/*
						" 단,사용자 추가의 할인 금액이 사용자 추가 구간의 금액보다 작은 경우는 다음 구간에서 할인 혜택 제공.  
						  예) ② 예시에서 사용자 추가 할인쿠폰이 220만원이 적용되었을때는 [ ~100인 구간에는 215만원 할인] , 
						  		나머지 5만원은 [101~300인 구간에서 할인] 적용."
						 */
						// (AMOUNT)프로모션 할인적용 금액
						if (a.totPrice < discountPolicy.num) {
							discountPolicy.num -= a.totPrice;
							a.discountPrice = a.totPrice;
							a.totPrice = 0;
						} else {
							a.totPrice -= discountPolicy.num;
							a.discountPrice = discountPolicy.num;
							discountPolicy.num = 0;
						}
					} else {
						a.totPrice = a.originPrice;
					}
					//장기선납할인금액
					a.periodDiscountPrice = a.totPrice * (periodDiscount / 100);
					a.totPrice *= (100 - periodDiscount) / 100; // 장기선납할인율 적용
					a.totPrice = Math.floor(a.totPrice / 10) * 10;
					// 할인 받은 금액 (프로모션,장기할인)
					a.discountTotPrice = (a.originPrice - a.totPrice) * -1;

					item.addUserDiscountMatrix.push(a);
				}
			}
			// 견적서에 넣을 real price
			item.addUserDiscountMatrix.forEach((row) => {
				row['totPriceReal'] = row.totPrice;
			});

			let disTot = 0; // 할인가 합계
			let sumTot = 0; // 금액 합계
			let sumOriginTot = 0; // 원가 합계
			let sumDiscount = 0; // 프로모션 할인받은 금액 합계
			let sumPeriodDiscount = 0; // 장기선납할인 합계

			for (let index in item.addUserDiscountMatrix) {
				let obj = item.addUserDiscountMatrix[index];
				disTot += obj.discountTotPrice;
				sumTot += obj.totPrice;
				sumOriginTot += obj.originPrice;
				sumDiscount += obj.discountPrice;
				sumPeriodDiscount += obj.periodDiscountPrice;
			}
			item['addUserTotNormalPrice'] = sumOriginTot; //원가 합계
			item['addUserTotDiscountPrice'] = sumTot; //할인액 합계
			item['addUserTotDiscount'] = disTot; //할인받은 금액 합계

			if (sumDiscount > 0) {
				item.discountPolicyModels[discountPolicyIndex].discountAmount = -1 * sumDiscount;
			}
			if (sumPeriodDiscount > 0) {
				item.periodPolicyModel.discountAmount.push(-1 * sumPeriodDiscount);
			}

			return item;
		},
		async setDiscountSum(item) {
			if (item.periodPolicyModel == undefined || item.periodPolicyModel == null) {
				return;
			}

			if (item.periodPolicyModel.discountAmount && item.periodPolicyModel.discountAmount.length > 0) {
				let discountAmountTot = 0;

				item.periodPolicyModel.discountAmount.forEach((val) => {
					discountAmountTot += parseInt(val);
				});

				item.periodPolicyModel['discountAmountTot'] = discountAmountTot;
			}

			// 무료체험일 경우 ...
			if (item.freeTrialPolicyModel) {
				if (item.freeTrialPolicyModel.usePolicy) {
					let membershipDiscountPolicyIndex = item.discountPolicyModels.findIndex(
						(obj) => obj.itemType == 'MEMBERSHIP_FEE',
					);

					if (membershipDiscountPolicyIndex > -1) {
						item.discountPolicyModels[membershipDiscountPolicyIndex]['discountAmountReal'] =
							(item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice) * -1;
						item.discountPolicyModels[membershipDiscountPolicyIndex].discountAmount = 0;
					}
					// 설치비
					item['membershipFeeNormalPriceReal'] = item.membershipFeeNormalPrice;
					item['membershipFeeDiscountPriceReal'] = item.membershipFeeDiscountPrice;
					item['membershipFeeDiscountReal'] = item.membershipFeeDiscount;

					item.membershipFeeNormalPrice = 0;
					item.membershipFeeDiscountPrice = 0;
					item.membershipFeeDiscount = 0;

					let defaultFeeDiscountPolicyIndex = item.discountPolicyModels.findIndex(
						(obj) => obj.itemType == 'DEFAULT_FEE',
					);

					if (defaultFeeDiscountPolicyIndex > -1) {
						item.discountPolicyModels[defaultFeeDiscountPolicyIndex]['discountAmountReal'] =
							item.discountPolicyModels[defaultFeeDiscountPolicyIndex].discountAmount;
						item.discountPolicyModels[defaultFeeDiscountPolicyIndex].discountAmount = 0;
					}
					// 기본료
					item['defaultFeeTotNormalPriceReal'] = item.defaultFeeTotNormalPrice;
					item['defaultFeeTotDiscountPriceReal'] = item.defaultFeeTotDiscountPrice;
					item['defaultFeeTotDiscountReal'] = item.defaultFeeTotDiscount;

					item.defaultFeeTotNormalPrice = 0;
					item.defaultFeeTotDiscountPrice = 0;
					item.defaultFeeTotDiscount = 0;

					// 사용자 추가금
					let addUserDiscountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'ADD_USER');
					if (addUserDiscountPolicyIndex > -1) {
						item.discountPolicyModels[addUserDiscountPolicyIndex]['discountAmountReal'] =
							item.discountPolicyModels[addUserDiscountPolicyIndex].discountAmount;
						item.discountPolicyModels[addUserDiscountPolicyIndex].discountAmount = 0;
					}
					item['addUserTotNormalPriceReal'] = item.addUserTotNormalPrice;
					item['addUserTotDiscountPriceReal'] = item.addUserTotDiscountPrice;
					item['addUserTotDiscountReal'] = item.addUserTotDiscount;

					item.addUserTotNormalPrice = 0;
					item.addUserTotDiscountPrice = 0;
					item.addUserTotDiscount = 0;

					// 사용자 추가금 할인 매트릭스 금액 0원 처리
					item.addUserDiscountMatrix.forEach((row) => {
						row.totPrice = 0;
					});
					// 장기선납
					if (item.periodPolicyModel !== undefined && item.periodPolicyModel !== null) {
						if (
							item.periodPolicyModel.discountAmount !== undefined &&
							item.periodPolicyModel.discountAmount !== null &&
							item.periodPolicyModel.discountAmount.length > 0
						) {
							item.periodPolicyModel['discountAmountReal'] = item.periodPolicyModel.discountAmount;

							item.periodPolicyModel.discountAmount = [];

							item.periodPolicyModel['discountAmountTotReal'] = item.periodPolicyModel.discountAmountTot;

							item.periodPolicyModel.discountAmountTot = 0;
						}
					}
				}
			}
			return item;
		},
	},
};
