<template>
	<!--@content start-->
	<div class="section_body">
		<div class="section_content section_join_start">
			<div class="container_join_start">
				<div class="com_form_large">
					<!--@멤버 수-->
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit">
								<span class="txt_large">사용자 수</span>
							</label>
							<div class="form_data">
								<span class="wrap_number">
									<!--<input class="txt" type="text" v-model="userCnt" />-->
									<span class="com_number" :class="{ disabled: isFree }">
										<button class="minus" @click="minusUserCnt" :disabled="isFree">
											<span class="ic_set ic_minus"></span>
										</button>
										<input
											v-doms-inputValidate:onlyNumber=""
											type="number"
											class="ipt_normal"
											v-model="userCnt"
											@blur="roundsUserCnt"
											:disabled="isFree"
											name="quantity"
										/>
										<button class="plus" :disabled="isFree" @click="plusUserCnt">
											<span class="ic_set ic_plus"></span>
										</button>
									</span>
									<span class="txt">명</span>
								</span>
								<span class="com_help">
									<span class="ic_set ic_info">
										<span class="tool_tip">
											총 100명 이상 사용 시,<br />
											사용자의 구간에 따라 최소 20% ~ 최대 60% 까지 할인율이 적용됩니다 <br />
											(장기 선납 할인과 중복 적용)
											<i class="tail_left"></i
										></span>
									</span>
								</span>
							</div>
						</div>
					</div>
					<!--@멤버십-->
					<div class="wrap_row fluid">
						<div class="row">
							<label class="form_tit">
								<span class="txt_large">기본 서비스</span>
								<!--<button
									v-if="fold['baseChannel'] == false"
									title="접기"
									v-on:click="fold['baseChannel'] = true"
								></button>
								<button
									v-if="fold['baseChannel'] == true"
									class="unfold"
									title="펼치기"
									v-on:click="fold['baseChannel'] = false"
								></button>-->
								<span class="ic_set ic_blank" v-on:click="openDaouOffice()" title="자세히보기"></span>
							</label>
							<div v-if="fold['baseChannel'] == false" class="form_data">
								<div class="grid_card">
									<div
										class="com_card"
										:class="{
											active: isActive[item.id],
										}"
										v-for="(item, index) in baseChannelList"
										:key="index"
										@click="selectBaseChannel(item)"
									>
										<div class="card_header">
											<div class="wrap_header">
												<div class="com_ck round">
													<label>
														<input type="checkbox" :disabled="isActive[item.id]" :checked="isActive[item.id]" />
														<span class="checkmark"></span>
													</label>
												</div>
												<h2>
													{{ serviceNameDisplay(item) }}
												</h2>
												<span v-if="item.useTooltip" class="com_help white">
													<span class="ic_set ic_info">
														<span class="tool_tip">
															CONTENT...
															<i class="tail_left"></i
														></span>
													</span>
												</span>
											</div>
											<!--<span class="txt_sub">
												{{
													serviceTypeDisplay(
														item.chargeType,
														item.serviceCategoryModel.serviceType,
													)
												}}
											</span>-->
										</div>
										<div class="card_body">
											<p class="txt_grade">
												1인
												<template v-if="item.addUserIntervalPolicyModel.defaultVolumeForAddUser > 0">
													{{ item.addUserIntervalPolicyModel.defaultVolumeForAddUser }}GB
												</template>
												기준
												{{ billingType[item.addUserIntervalPolicyModel.billingType] }}
											</p>
											<p class="txt_price" v-if="item.chargeType != 'FREE'">
												<span class="currency">￦</span>
												<span class="price">
													{{ item.addUserIntervalPolicyModel.price | currency }}
												</span>
											</p>
											<p class="txt_price" v-if="item.chargeType === 'FREE'">
												<span class="price">무료</span>
											</p>
											<table class="com_tb_metadata">
												<tr v-if="item.defaultFeePolicyModel.usePolicy">
													<td class="tit"><span class="txt">기본료</span></td>
													<td class="data">
														<span class="txt"
															>{{ item.defaultFeePolicyModel.price | currency }}원
															{{ billingType[item.defaultFeePolicyModel.billingType] }}
														</span>
													</td>
												</tr>
												<tr v-if="item.membershipFeePolicyModel.usePolicy">
													<td class="tit">
														<span class="txt">설치비</span>
													</td>
													<td class="data">
														<span class="txt">
															{{ item.membershipFeePolicyModel.price | currency }}원
															{{ billingType[item.membershipFeePolicyModel.billingType] }}
														</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">사용자</span>
													</td>
													<td class="data">
														<span v-if="!isActive[item.id]" class="txt">
															총 {{ item.addUserIntervalPolicyModel.minimum }}명
														</span>
														<span v-if="isActive[item.id]" class="txt"> 총 {{ userCnt }}명 </span>
													</td>
												</tr>
												<tr>
													<td class="tit"><span class="txt">사용기간</span></td>
													<td class="data">
														<div class="com_slt">
															<select
																class="slt_normal"
																v-model="item.usePeriod"
																v-on:change="usePeriod = item.usePeriod"
																:disabled="!isActive[item.id]"
																@click.stop="stopBubbling"
															>
																<option
																	v-for="row in item.periodDisplay"
																	:key="row.value"
																	:value="row.value"
																	v-html="row.display"
																></option>
															</select>
														</div>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row" v-if="usePromotionAdvertisementApply()">
							<label class="form_tit"></label>
							<div class="form_data">
								<div class="com_promotion promotion_2021_11_15" v-html="getPromotionAdvertisementApplyHtml()">
									<span class="com_tag_badge">
										<span class="txt">추천</span>
									</span>
									<span class="txt_16">
										{{ getPromotionAdvertisementApplyHead() }}
										<span class="em">{{ getPromotionAdvertisementApplyBody() }}</span>
										{{ getPromotionAdvertisementApplyTail() }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<!--@부가 서비스-->
					<div
						v-if="this.$store.getters.getBasicItemSelected.length && addonChannelList.length && showAddonChannel"
						class="wrap_row"
					>
						<div class="row">
							<label class="form_tit">
								<span class="txt_large">부가 서비스</span>
								<!--<button
									v-if="fold['addonChannel'] == false"
									title="접기"
									v-on:click="fold['addonChannel'] = true"
								></button>
								<button
									v-if="fold['addonChannel'] == true"
									title="펼치기"
									v-on:click="fold['addonChannel'] = false"
								></button>-->
							</label>
							<div v-if="fold['addonChannel'] == false" class="form_data">
								<template v-for="(item, index) in addonChannelList">
									<div
										class="com_card_normal card_addon"
										:class="{
											active: item.isSelected,
										}"
										@click="addonItemSelected = item"
										:key="index"
									>
										<div class="com_ck bk">
											<label
												><input
													type="checkbox"
													v-on:click="selectAddonChannel(item)"
													:disabled="isRequiredAddonChannel(item)"
													:checked="item.isSelected" />
												<span class="checkmark"></span
											></label>
										</div>
										<div class="wrap_row_card">
											<div class="wrap_box">
												<span v-if="isAccountAddonChannel(item)" class="ic_svc ic_accounting"></span>
												<span v-if="item.name == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
												<span v-if="item.name == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
												<span v-if="item.name == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
												<span v-if="item.name == 'TMSe 장비일체형 T'" class="ic_svc ic_tmse_share"></span>
												<span class="tit">{{ item.name }}</span>
												<span v-if="item.freeTrialPolicyModel.usePolicy == true" class="com_tag_badge">
													<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span>
												</span>
											</div>
										</div>
										<div class="wrap_meta_addon">
											<table class="com_tb_metadata">
												<tbody>
													<tr>
														<td class="tit"><span class="txt">기본료</span></td>
														<td class="data">
															<span class="txt"
																>{{ item.defaultFeePolicyModel.price | currency }}원
																{{ billingType[item.defaultFeePolicyModel.billingType] }}</span
															>
														</td>
													</tr>
													<tr>
														<td class="tit"><span class="txt">설치비</span></td>
														<td class="data">
															<span class="txt"
																>{{ item.membershipFeePolicyModel.price | currency }}원
																{{ billingType[item.membershipFeePolicyModel.billingType] }}</span
															>
														</td>
													</tr>
													<tr>
														<td class="tit">
															<span class="txt">사용자 (1명)</span>
														</td>
														<td class="data">
															<span class="txt"
																>{{ item.addUserIntervalPolicyModel.price | currency }}원
																{{ billingType[item.addUserIntervalPolicyModel.billingType] }}</span
															>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--@ 옵션-->

			<div class="container_join_start" v-if="optionItemPerAddon.length > 0">
				<div class="con_header">
					<span class="tit">옵션</span>
					<!--	<button
						v-if="fold['option'] == false"
						title="접기"
						v-on:click="fold['option'] = true"
					></button>
					<button
						v-if="fold['option'] == true"
						title="펼치기"
						v-on:click="fold['option'] = false"
					></button>-->
				</div>

				<div class="com_form_large" v-if="fold['option'] == false">
					<!--@옵션-->
					<div
						class="wrap_row"
						v-for="(optionPerService, index) in optionItemPerAddon"
						:key="'label_' + optionPerService.service.code"
					>
						<!--@옵션 - 1-->
						<div class="row">
							<label class="form_tit">
								<span class="txt_medium">
									{{ optionPerService.service.name }}
								</span>
							</label>
							<div class="form_data">
								<div class="com_card_normal card_svc_option">
									<template v-for="(option, index) in optionPerService.options">
										<!--@가상 도메인-->
										<template v-if="option.groupModel.isGroupped && option.groupModel.name.indexOf('가상') > -1">
											<div
												class="wrap_row_card"
												v-for="item in option.items"
												:key="'virtualDomain' + item.code + index"
											>
												<span class="tit">가상 도메인</span>
												<span v-if="item.displaySectionModel.useTooltip" class="com_help">
													<span class="ic_set ic_info">
														<span class="tool_tip">
															{{ item.displaySectionModel.tooltipDesc }}
															<i class="tail_left"></i
														></span>
													</span>
												</span>
												<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
													<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
													<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
												</span>

												<div class="wrap_meta_ctrl">
													<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
														<span class="price">견적가</span>
													</template>
													<template v-else>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
															>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
																item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
															}}개</span
														>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
															>{{ item.channelItemSalesSectionModel.price | currency }}원</span
														>
													</template>
													<span class="price billing_type">{{
														optionBillingType[item.channelItemSalesSectionModel.billingType]
													}}</span>

													<span
														class="com_number"
														v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
													>
														<button class="minus" v-on:click="downCntOptionItem(item, optionPerService.service)">
															<span class="ic_set ic_minus"></span>
														</button>
														<span class="txt">
															{{ item.orderCount }}
														</span>
														<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
															<span class="ic_set ic_plus"></span>
														</button>
													</span>
												</div>
												<div v-if="item.orderCount > 0" class="wrap_sub">
													<table class="com_tb_metadata tb_sub_option">
														<tbody>
															<tr
																v-for="index in item.orderCount"
																:key="optionPerService.service.code + item.itemCode + index"
															>
																<td class="tit">
																	<span class="ic_set ic_depth"></span>
																	<span class="txt"> 가상 도메인 {{ index }} </span>
																</td>
																<td class="data">
																	<div class="com_ipt outlined">
																		<input
																			class="ipt_normal"
																			type="text"
																			value="sample"
																			v-model="item.domainText[index - 1]"
																			placeholder="sample.com"
																			@change="handleDomainTextChange(item)"
																			@keyup="handleDomainTextChange(item)"
																		/>
																	</div>
																	<button
																		class="del"
																		title="삭제"
																		v-on:click="
																			item.domainText.splice(index - 1, 1);
																			downCntOptionItem(item, optionPerService.service);
																		"
																	>
																		<i class="ic_set ic_del"></i>
																	</button>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</template>

										<!--@용량 추가-->
										<div
											class="wrap_row_card"
											v-if="option.groupModel.isGroupped && option.groupModel.name.indexOf('용량') > -1"
											:key="index"
										>
											<span class="tit">용량 추가</span>
											<div class="wrap_sub">
												<table class="com_tb_metadata tb_sub_option">
													<tbody>
														<tr v-for="item in option.items" :key="item.code">
															<td class="tit">
																<span class="ic_set ic_depth"></span>
																<span class="txt">{{ item.displaySectionModel.displayName }}</span>
																<span v-if="item.displaySectionModel.useTooltip" class="com_help">
																	<span class="ic_set ic_info">
																		<span class="tool_tip">
																			{{ item.displaySectionModel.tooltipDesc }}
																			<i class="tail_left"></i
																		></span>
																	</span>
																</span>
																<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
																	<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
																	<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt"
																		>추천</span
																	>
																</span>
															</td>
															<td class="data">
																<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
																	<span class="price">견적가</span>
																</template>
																<template v-else>
																	<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
																		>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
																			item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
																		}}개</span
																	>
																	<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
																		>{{ item.channelItemSalesSectionModel.price | currency }}원</span
																	>
																</template>
																<span class="price billing_type">
																	{{ optionBillingType[item.channelItemSalesSectionModel.billingType] }}
																</span>

																<span
																	class="com_number"
																	v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
																>
																	<!--
															:disabled="
																extensionPeriod == 0 &&
																	item.usingCount > 0 &&
																	item.orderCount - 1 < item.usingCount
															"
														-->
																	<button class="minus" v-on:click="downCntOptionItem(item, optionPerService.service)">
																		<span class="ic_set ic_minus"></span>
																	</button>
																	<input class="ipt_normal" type="number" name="quantity" v-model="item.orderCount" />
																	<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
																		<span class="ic_set ic_plus"></span>
																	</button>
																</span>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>

										<!--@ 그루핑 된 기타 옵션-->
										<template
											v-if="
												option.groupModel.isGroupped &&
												option.groupModel.name.indexOf('용량') == -1 &&
												option.groupModel.name.indexOf('가상') == -1
											"
										>
											<div class="wrap_row_card" :key="index">
												<span class="tit"
													>{{ option.groupModel.name }}

													<a v-if="option.groupModel.selectionType == 'RADIO'"> (택 1)</a>
												</span>
												<div class="wrap_sub">
													<table class="com_tb_metadata tb_sub_option">
														<tbody>
															<tr v-for="item in option.items" :key="item.code">
																<td class="tit">
																	<span class="ic_set ic_depth"></span>
																	<span class="txt">{{ item.displaySectionModel.displayName }}</span>
																	<span v-if="item.displaySectionModel.useTooltip" class="com_help">
																		<span class="ic_set ic_info">
																			<span class="tool_tip">
																				{{ item.displaySectionModel.tooltipDesc }}
																				<i class="tail_left"></i
																			></span>
																		</span>
																	</span>
																	<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
																		<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
																		<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt"
																			>추천</span
																		>
																	</span>
																</td>
																<td
																	class="data"
																	:colspan="
																		item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity
																			? '2'
																			: '0'
																	"
																>
																	<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
																		<span class="price">견적가</span>
																	</template>
																	<template v-else>
																		<span
																			v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'"
																			class="price"
																			>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
																				item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
																			}}개</span
																		>
																		<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
																			>{{ item.channelItemSalesSectionModel.price | currency }}원</span
																		>
																	</template>
																	<span class="price billing_type">
																		{{ optionBillingType[item.channelItemSalesSectionModel.billingType] }}
																	</span>

																	<span
																		class="com_number"
																		v-if="
																			item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity
																		"
																	>
																		<button
																			class="minus"
																			v-on:click="downCntOptionItem(item, optionPerService.service)"
																		>
																			<span class="ic_set ic_minus"></span>
																		</button>
																		<input class="ipt_normal" type="number" name="quantity" v-model="item.orderCount" />
																		<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
																			<span class="ic_set ic_plus"></span>
																		</button>
																	</span>
																</td>
																<td
																	class="check"
																	v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
																>
																	<div class="com_ck bk">
																		<label
																			><input
																				type="checkbox"
																				:disabled="isItemDisable(item)"
																				:checked="isItemChecked(item)"
																				v-on:click="
																					item.orderCount == 0
																						? upCntOptionItem(item, optionPerService.service)
																						: downCntOptionItem(item, optionPerService.service)
																				" /><span class="checkmark"></span
																		></label>
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</template>

										<!--@ 그루핑 되지 않은 기타 옵션-->
										<template v-if="!option.groupModel.isGroupped">
											<div class="wrap_row_card" v-for="item in option.items" :key="item.code">
												<div
													class="com_ck bk"
													v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
												>
													<label
														><input
															type="checkbox"
															:disabled="isItemDisable(item)"
															:checked="isItemChecked(item)"
															v-on:click="
																item.orderCount == 0
																	? upCntOptionItem(item, optionPerService.service)
																	: downCntOptionItem(item, optionPerService.service)
															" /><span class="checkmark"></span
													></label>
												</div>

												<span class="tit">
													{{ item.displaySectionModel.displayName }}
												</span>
												<span v-if="item.displaySectionModel.useTooltip" class="com_help">
													<span class="ic_set ic_info">
														<span class="tool_tip">
															{{ item.displaySectionModel.tooltipDesc }}
															<i class="tail_left"></i
														></span>
													</span>
												</span>
												<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
													<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
													<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
												</span>
												<div
													class="wrap_meta_data"
													v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
												>
													<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
														<span class="price">견적가</span>
													</template>
													<template v-else>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
															>{{ item.channelItemSalesSectionModel.price | currency }} 원/{{ unitCount(item) }}개</span
														>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
															>{{
																item.channelItemSalesSectionModel.priceType === 'ESTIMATE'
																	? '견적가'
																	: (item.channelItemSalesSectionModel.price | currency) + '원'
															}}
														</span>
													</template>

													<span class="price billing_type">
														{{ optionBillingType[item.channelItemSalesSectionModel.billingType] }}
													</span>
												</div>
												<div
													class="wrap_meta_ctrl"
													v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
												>
													<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
														<span class="txt">견적가</span>
													</template>
													<template v-else>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
															>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
																item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
															}}개</span
														>
														<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
															>{{ item.channelItemSalesSectionModel.price | currency }}원
														</span>
													</template>
													<span class="price billing_type">
														{{ optionBillingType[item.channelItemSalesSectionModel.billingType] }}
													</span>

													<span class="com_number">
														<button class="minus" v-on:click="downCntOptionItem(item, optionPerService.service)">
															<span class="ic_set ic_minus"></span>
														</button>
														<span class="txt">{{ item.orderCount }}</span>
														<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
															<span class="ic_set ic_plus"></span>
														</button>
													</span>
												</div>
											</div>
										</template>
									</template>
								</div>
								<span class="txt" v-if="index == 0">
									※ 추가 용량, 멀티컴퍼니, 가상도메인등의 상품 상세 문의 : 1577-3019
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<SideBar
			v-on:openModal="openModal"
			:goApplyService="goApplyService"
			:type="'NEW'"
			:serviceType="selectedServiceType"
		>
		</SideBar>
	</div>
</template>
<script>
import SideBar from '@/components/apply/SideBar.vue';
import ModalComponent from '@/components/code/modal/ModalComponent';
import { product } from '@/views/mixinsProduct.js';
import { calculate } from '@/views/apply/mixinsNewCalculate.js';
import { promotion } from '@/views/common/promotion.js';
import { productClassifier } from '@/views/mixinProductClassifier.js';
export default {
	mixins: [product, calculate, promotion, productClassifier],
	data() {
		return {
			goApplyService: false,
			preChargeType: 'PAY',
			waitForUsePeriodChange: false,
			prevBaseChannel: [],
			prevAddonChannels: [],
			startDate: '',
			endDate: '',
			fold: {
				baseChannel: false,
				addonChannel: false,
				option: false,
			},
			baseChannelList: [], // 기본서비스 (API)
			addonChannelList: [], // 기본서비스 (API)
			isActive: {}, // 기본서비스 선택여부 (1개)
			optionItemPerAddon: [], //부가서비스 별, 옵션 상품
			basicItemSelected: null,
			addonItemSelected: null,
			userCnt: 10,
			usePeriod: 12,
			promotionAdvertisementApply: {
				use: false,
			},
			termsList: [],
			stepBtnInfo: {
				next: '다음',
				nextLink: '/apply/step2',
			},
			slidemenuTop: 0,
			modalData: null,
			modalFlag: false,
			totQuota: 0,
			showAddonChannel: true,
			selectedServiceType: 'SAAS',
		};
	},
	computed: {
		isDirectFree() {
			let link = document.location.href;
			return link.length - 24 === link.indexOf('/apply/step1/free-direct') ? true : false;
		},
		currentChargeType() {
			if (this.waitForUsePeriodChange) {
				return this.preChargeType;
			}

			const options = [];
			if (this.$store.getters.getBasicItemSelected.length) {
				const baseChannel = this.$store.getters.getBasicItemSelected[0];
				options.push(...baseChannel.optionItemSelected);
			}

			this.$store.getters.getAddonItemSelected.forEach((addonChannel) => {
				options.push(...addonChannel.optionItemSelected);
			});

			const monthlyOptions = options.filter(
				(option) =>
					option.channelItemSalesSectionModel.billingType === 'MONTHLY' &&
					option.channelItemSalesSectionModel.price > 0 &&
					option.orderCount > 0,
			);

			const chargeType = this.basicItemSelected
				? this.basicItemSelected.chargeType === 'FREE'
					? monthlyOptions.length
						? 'FREE_MONTHLY'
						: 'FREE'
					: 'PAY'
				: 'PAY';

			this.setPreChargeType(chargeType);
			return chargeType;
		},
		serviceTypeDisplay() {
			return (chargeType, serviceType) => {
				if (chargeType == 'FREE') {
					return 'SaaS-Free';
				} else {
					return this.$constants.serviceTypeDisplay[serviceType];
				}
			};
		},
		isFree() {
			return this.basicItemSelected ? this.basicItemSelected.chargeType == 'FREE' : false;
		},
	},
	watch: {
		async currentChargeType() {
			if (this.currentChargeType !== 'PAY') {
				const index = this.baseChannelList.findIndex((baseChannel) => baseChannel.code === this.basicItemSelected.code);
				const baseChannel = this.baseChannelList[index];
				baseChannel.periodDisplay = await this.makeUsePeriodSelectDisplay(
					baseChannel.periodPolicyModel,
					baseChannel.serviceCategoryModel.serviceType,
					this.currentChargeType,
				);
				baseChannel.usePeriod =
					this.currentChargeType === 'FREE'
						? 12
						: this.currentChargeType === 'FREE_MONTHLY'
						? 1
						: baseChannel.usePeriod;
				this.usePeriod = baseChannel.usePeriod;
				this.baseChannelList.splice(index, 1, baseChannel);
			}

			this.$store.commit('setCurrentChargeType', this.currentChargeType);
		},
		async userCnt() {
			let title = '최대 사용자 수 제한';
			let message;

			if (this.isVpcOrOnPremise() && this.userCnt > 99990) {
				message = '사용자 수는 최대 99990명 까지 신청 가능합니다.';
				this.userCnt = 99990;
				await this.$ms.dialog.alert(title, message);
				return;
			}

			if (!this.isVpcOrOnPremise() && this.userCnt > 99999) {
				message = '사용자 수는 최대 99999명 까지 신청 가능합니다.';
				this.userCnt = 99999;
				await this.$ms.dialog.alert(title, message);
				return;
			}

			// [멤버 수] 입력 값 변경 시, 기본서비스 + 부가서비스 전체 재 계산
			const basicItemSelected = await this.$store.getters.getBasicItemSelected;
			const addonChannelSelected = await this.$store.getters.getAddonItemSelected;

			if (basicItemSelected.length > 0) {
				await basicItemSelected.reduce((previous, current) => {
					return previous.then(async () => {
						let item = this.cloneObj(current);

						item = await this.setBaseInfo(item);
						item = await this.setMembershipFeeDiscountMatrix(item);
						item = await this.setDefaultFeeDiscountMatrix(item);
						item = await this.setAddUserDiscountMatrix(item);
						item = await this.setDiscountSum(item);
						await this.$store.commit('setBasicItemSelected', item);
					});
				}, Promise.resolve());
			}

			if (addonChannelSelected.length > 0) {
				await addonChannelSelected.reduce((previous, current) => {
					return previous.then(async () => {
						let item = this.cloneObj(current);
						item = await this.setBaseInfo(item);
						item = await this.setMembershipFeeDiscountMatrix(item);
						item = await this.setDefaultFeeDiscountMatrix(item);
						item = await this.setAddUserDiscountMatrix(item);
						item = await this.setDiscountSum(item);
						await this.$store.commit('updateAddonChannelSelected', item);
					});
				}, Promise.resolve());
			}

			// 기본, 부가 옵션 재계산
			await this.optionItemPerAddon.reduce((previousService, currentService) => {
				return previousService.then(async () => {
					await currentService.options.reduce((previousOption, currentOption) => {
						return previousOption.then(async () => {
							await currentOption.items.reduce((previousItem, currentItem) => {
								return previousItem.then(async () => {
									if (currentItem.orderCount > 0) {
										let optionItem = this.cloneObj(currentItem);
										//유료상품 기본서비스 수대로 과금일 경우, 옵션수량을 현재 사용자수로 수정한다.
										if (this.isChangeOrderCount(optionItem)) {
											optionItem.orderCount = this.userCnt;
											currentItem.orderCount = this.userCnt;
										}
										if (currentService.service.code.indexOf('ADDON-') > -1) {
											optionItem = await this.setInfo_ACO(
												optionItem,
												currentService.service.freeTrialPolicyModel.usePolicy,
												currentService.service.freeTrialPolicyModel.usagePeriod,
											);
											await this.$store.commit('addAddonOptionSelected', {
												addonCode: currentService.service.code,
												data: optionItem,
											});
										} else {
											optionItem = await this.setInfo_BCO(optionItem);
											await this.$store.commit('addBaseOptionSelected', optionItem);
										}
									}
								});
							}, Promise.resolve());
						});
					}, Promise.resolve());
				});
			}, Promise.resolve());

			await this.setTotQuota();
		},
		async usePeriod() {
			this.waitForUsePeriodChange = true;
			const { data } = await this.$store.dispatch('GET_END_DATE', {
				startDate: this.startDate,
				period: this.usePeriod,
			});
			this.endDate = data;

			await this.$store.commit('setEndDate', data);

			// [사용기간] 입력 값 변경 시, 기본서비스 + 부가서비스 전체 재 계산
			const basicItemSelected = await this.$store.getters.getBasicItemSelected;
			const addonChannelSelected = await this.$store.getters.getAddonItemSelected;

			if (basicItemSelected.length > 0) {
				await basicItemSelected.reduce((previous, current) => {
					return previous.then(async () => {
						let item = this.cloneObj(current);
						item = await this.setBaseInfo(item);
						item = await this.setMembershipFeeDiscountMatrix(item);
						item = await this.setDefaultFeeDiscountMatrix(item);
						item = await this.setAddUserDiscountMatrix(item);
						item = await this.setDiscountSum(item);
						await this.$store.commit('setBasicItemSelected', item);
					});
				}, Promise.resolve());
			}

			if (addonChannelSelected.length > 0) {
				await addonChannelSelected.reduce((previous, current) => {
					return previous.then(async () => {
						let item = this.cloneObj(current);
						item = await this.setBaseInfo(item);
						item = await this.setMembershipFeeDiscountMatrix(item);
						item = await this.setDefaultFeeDiscountMatrix(item);
						item = await this.setAddUserDiscountMatrix(item);
						item = await this.setDiscountSum(item);
						await this.$store.commit('updateAddonChannelSelected', item);
					});
				}, Promise.resolve());
			}
			// 기본, 부가 옵션 재계산
			await this.optionItemPerAddon.reduce((previousService, currentService) => {
				return previousService.then(async () => {
					await currentService.options.reduce((previousOption, currentOption) => {
						return previousOption.then(async () => {
							await currentOption.items.reduce((previousItem, currentItem) => {
								return previousItem.then(async () => {
									if (currentItem.orderCount > 0) {
										let optionItem = this.cloneObj(currentItem);
										if (currentService.service.code.indexOf('ADDON-') > -1) {
											optionItem = await this.setInfo_ACO(
												optionItem,
												currentService.service.freeTrialPolicyModel.usePolicy,
												currentService.service.freeTrialPolicyModel.usagePeriod,
											);
											await this.$store.commit('addAddonOptionSelected', {
												addonCode: currentService.service.code,
												data: optionItem,
											});
										} else {
											optionItem = await this.setInfo_BCO(optionItem);
											await this.$store.commit('addBaseOptionSelected', optionItem);
										}
									}
								});
							}, Promise.resolve());
						});
					}, Promise.resolve());
				});
			}, Promise.resolve());
			this.waitForUsePeriodChange = false;
		},
	},
	async created() {
		document.title = '다우오피스 멤버십 신청';
		const partnerCode = this.$route.query.id;
		if (partnerCode) {
			try {
				const { data } = await this.$store.dispatch('GETPARTNER', partnerCode);
				if (data) {
					this.$store.commit('setPartnerInfo', data);
				}
			} catch (e) {
				console.log(e);
			}
		}
		let temp = await this.$store.getters.getBasicItemSelected;

		if (temp.length > 0) {
			this.prevBaseChannel = this.cloneObj(temp);
		}
		temp = [];
		temp = await this.$store.getters.getAddonItemSelected;
		if (temp.length > 0) {
			this.prevAddonChannels = this.cloneObj(temp);
		}
		if (this.$store.getters.getUsePeriod > 0) {
			// 기존 선택 사용기간
			this.usePeriod = this.$store.getters.getUsePeriod;
		}

		const prevUserCnt =
			this.$store.getters.getBasicItemSelected.length > 0 ? this.$store.getters.getBasicItemSelected[0].userCnt : 10;

		this.userCnt = prevUserCnt;
		const today = new Date();
		const year = new String(today.getFullYear());
		let month = new String(today.getMonth() + 1);
		let day = new String(today.getDate());
		if (month.length == 1) {
			month = '0' + month;
		}
		if (day.length == 1) {
			day = '0' + day;
		}
		const todayStr = year + '-' + month + '-' + day;
		this.startDate = todayStr;
		await this.$store.commit('setStartDate', todayStr);
		await this.getTodayPromotions().then(() => {
			this.getbaseChannelList();
			this.promotionAdvertisementApply = this.$store.getters.getPromotionAdvertisementApply;
		});

		if (this.isDirectFree) {
			this.goApplyService = true;
		}
	},
	mounted() {
		// 전체 데이터 초기화
		//this.$store.commit('clearApplyServiceInfo');
		this.addonItemSelected = null;
		this.addonChannelList = [];
		this.baseChannelOptionItemList = [];
		this.optionItemPerAddon = [];
		// 사이드바 위치 선정 위해, 스크롤 이벤트 등록
		window.addEventListener('scroll', this.detectWindowScrollY);
	},
	beforeDestory() {
		window.removeEventListener('scroll', this.detectWindowScrollY);
	},
	methods: {
		setPreChargeType(chargeType) {
			this.preChargeType = chargeType;
		},
		usePromotionAdvertisementApply() {
			return this.promotionAdvertisementApply.use;
		},
		getPromotionAdvertisementApplyHtml() {
			return this.promotionAdvertisementApply.html;
		},
		getPromotionAdvertisementApplyHead() {
			return this.promotionAdvertisementApply.head;
		},
		getPromotionAdvertisementApplyBody() {
			return this.promotionAdvertisementApply.body;
		},
		getPromotionAdvertisementApplyTail() {
			return this.promotionAdvertisementApply.tail;
		},
		stopBubbling() {
			return false;
		},
		setUserCnt() {
			this.userCnt = 10;
		},
		minusUserCnt() {
			if (!this.basicItemSelected) {
				return this.userCnt;
			}

			const minimumUser = this.basicItemSelected.addUserIntervalPolicyModel.minimum;

			if (parseInt(this.userCnt) <= parseInt(minimumUser)) {
				this.$ms.dialog.alert('사용자는 10명 이상부터 신청 할 수 있습니다.');
				return minimumUser;
			}
			this.userCnt = parseInt(this.userCnt) - this.getUpdateCnt();
		},
		plusUserCnt() {
			this.userCnt = parseInt(this.userCnt) <= 99999 ? (this.userCnt += this.getUpdateCnt()) : this.userCnt;
		},
		getUpdateCnt() {
			return this.isVpcOrOnPremise() ? 10 : 1;
		},
		isVpcOrOnPremise() {
			return this.selectedServiceType === 'VPC' || this.selectedServiceType === 'ON_PREMISE';
		},
		roundsUserCnt(e) {
			if (this.isVpcOrOnPremise()) {
				if (e.target.value % 10 > 0) {
					this.$ms.dialog.alert('단독/설치형은 10명 단위로만 설정 가능합니다.');
				}
				this.userCnt = Math.round(e.target.value / 10) * 10;
			}
			if (this.userCnt < 10) {
				this.userCnt = 10;
			}
		},
		async setTotQuota() {
			const basicItemSelected = await this.$store.getters.getBasicItemSelected;

			if (!basicItemSelected || basicItemSelected.length == 0) return;

			let quatoItemTot = 0;
			if (basicItemSelected[0].optionItemSelected && basicItemSelected[0].optionItemSelected.length > 0) {
				const quatoItem = basicItemSelected[0].optionItemSelected.filter(
					(obj) => obj.groupModel.name.indexOf('용량') > -1,
				);

				if (quatoItem.length > 0) {
					await quatoItem.forEach((obj) => (quatoItemTot += parseInt(obj.orderCount) * parseInt(obj.itemValue)));
				}
			}

			this.totQuota =
				parseInt(
					basicItemSelected[0].defaultFeePolicyModel
						? basicItemSelected[0].defaultFeePolicyModel.defaultVolume
							? basicItemSelected[0].defaultFeePolicyModel.defaultVolume
							: 0
						: 0,
				) +
				parseInt(basicItemSelected[0].addUserCnt) *
					parseInt(
						basicItemSelected[0].addUserIntervalPolicyModel
							? basicItemSelected[0].addUserIntervalPolicyModel.defaultVolumeForAddUser
							: 0,
					) +
				parseInt(quatoItemTot);

			await this.$store.commit('setTotQuota', this.totQuota);
		},
		async openDaouOffice() {
			window.open('https://daouoffice.com/price.jsp', '서비스 유형');
		},
		openYearEndEvent() {
			window.open('https://www.daouoffice.com/2021_promotion.jsp');
		},
		cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		priceCutting(aprice, stype, n) {
			// 금액, 타입, 절삭금액 단위
			// 원단위처리(R:반올림, C:올림, F:버림)
			var remove_price = 0;
			stype = stype ? stype : 'R';
			remove_price = aprice / n;

			if (stype == 'F') {
				remove_price = Math.floor(remove_price);
			} else if (stype == 'R') {
				remove_price = Math.round(remove_price);
			} else if (stype == 'C') {
				remove_price = Math.ceil(remove_price);
			}

			remove_price = remove_price * n;
			return remove_price;
		},
		// 사용자 구간 모달창 open
		openModal(modalData) {
			this.$modal.show(
				ModalComponent,
				{
					item: modalData,
				},
				{
					width: '560px',
					height: '600px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
		// 사용자 구간 모달창 close
		closeModal() {
			this.modalFlag = false;
			this.modalData = null;
		},

		// 기본서비스 리스트 조회
		async getbaseChannelList() {
			try {
				let params = [window.__env.code.FREE, window.__env.code.SAAS, window.__env.code.VPC];

				const prevSelectCode =
					this.$store.getters.getBasicItemSelected.length > 0
						? this.$store.getters.getBasicItemSelected[0].code
						: 'NONE';

				await params.reduce((previous, current) => {
					return previous.then(async () => {
						let { data } = await this.$store.dispatch('GET_BASECHANNELLIST', current);
						let serviceType = data.serviceCategoryModel.serviceType;

						let promotionDiscount = this.$store.getters.getPromotionDiscount;

						if (promotionDiscount.serviceType && promotionDiscount.serviceType.includes(serviceType)) {
							data.periodPolicyModel.discountRate = [];
							data.periodPolicyModel.discountRate.push({
								month: 12, //기본 선택 개월
								rate: 0, //장기선납할인 제거
							});

							data['periodDisplay'] = promotionDiscount.periodDisplay;
							data['usePeriod'] = promotionDiscount.usePeriod;
							data['promotionDiscount'] = promotionDiscount;
						} else {
							data['periodDisplay'] = await this.makeUsePeriodSelectDisplay(
								data.periodPolicyModel,
								serviceType,
								data.chargeType,
							);

							if ('SAAS' === serviceType) {
								data['usePeriod'] = 1;
							} else {
								data['usePeriod'] = 12;
							}
						}

						this.baseChannelList.push(data);
						if (prevSelectCode !== 'NONE') {
							if (data.code == prevSelectCode) {
								// 공유형 default 선택
								data.usePeriod = this.usePeriod;
								await this.selectBaseChannel(data);
							} else {
								this.$set(this.isActive, data.id, false);
							}
						} else {
							const serviceTypeParam = this.$route.query.servicetype;
							if (!serviceTypeParam && data.chargeType === 'FREE') {
								await this.selectBaseChannel(data);
							} else if (data.serviceCategoryModel.serviceType == serviceTypeParam) {
								// 공유형 default 선택
								await this.selectBaseChannel(data);
							} else {
								this.$set(this.isActive, data.id, false);
							}
						}
					});
				}, Promise.resolve());
			} catch (error) {
				console.log(error);
			}
		},
		// API response 데이터를 사용기간 디스플레이 format으로 setting
		async makeUsePeriodSelectDisplay(periodPolicyModel, serviceType, chargeType) {
			let result = [];
			// 신규 신청 시, 사용 기간
			let newOrderUsingTerms;

			if (serviceType == 'SAAS' && chargeType == 'FREE') {
				newOrderUsingTerms = [12];
			} else if (serviceType == 'SAAS') {
				newOrderUsingTerms = [1, 12];
			} else {
				newOrderUsingTerms = [12];
			}
			// 장기선납 할인 中 일 경우
			if (periodPolicyModel.useLongTermPrepaymentDiscount) {
				let discountRate = periodPolicyModel.discountRate;

				for (let i in newOrderUsingTerms) {
					let ii = discountRate.findIndex((obj) => obj.month == newOrderUsingTerms[i]);
					// 장기선납 문구 추가
					if (ii > -1) {
						result.push({
							value: parseInt(newOrderUsingTerms[i]),
							display: newOrderUsingTerms[i].toString().concat('개월(장기선납', discountRate[ii].rate, '%할인)'),
						});
					} else if (newOrderUsingTerms[i] == 1) {
						result.push({
							value: parseInt(newOrderUsingTerms[i]),
							display: newOrderUsingTerms[i].toString().concat('개월(카드자동결제)'),
						});
					} else {
						result.push({
							value: parseInt(newOrderUsingTerms[i]),
							display: newOrderUsingTerms[i].toString().concat('개월'),
						});
					}
				}
			} else {
				for (let idx in newOrderUsingTerms) {
					result.push({
						value: parseInt(newOrderUsingTerms[idx]),
						display:
							chargeType != 'FREE'
								? newOrderUsingTerms[idx] === 1
									? newOrderUsingTerms[idx].toString().concat('개월(카드자동결제)')
									: newOrderUsingTerms[idx].toString().concat('개월')
								: '무제한',
					});
				}
			}

			return result;
		},
		// 기본서비스 선택 시 귀속된 기본서비스 옵션상품들 조회
		async getBaseChannelOptionItemList(param, service) {
			try {
				let { data } = await this.$store.dispatch('GET_BASECHANNEL_OPTIONITEM_LIST', param); // 기본서비스 옵션

				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}
				const seperatedOptions = await this.seperateByItemGroup(data);
				await seperatedOptions.reduce((previousOptionGroup, optionGroup) => {
					return previousOptionGroup.then(async () => {
						await optionGroup.items.reduce((previousKey, key) => {
							return previousKey.then(async () => {
								if (
									key.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity &&
									key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum > 0
								) {
									await this.setCntOptionItem(
										key,
										service,
										key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum,
									);
								} else {
									// 사용중 mapping
									key['orderCount'] = 0;
								}
							});
						}, Promise.resolve());
					});
				}, Promise.resolve());
				return seperatedOptions;
			} catch (error) {
				console.log(error);
			}
		},
		async seperateByItemGroup(items) {
			if (items.length > 0) {
				items.forEach((item) => {
					if (!item.groupModel) {
						item['groupModel'] = {
							code: item.code,
							name: item.name,
							isGroupped: false,
						};
					} else {
						item.groupModel['isGroupped'] = true;
					}
				});

				items.sort((a, b) => {
					return a.groupModel.code < b.groupModel.code ? -1 : a.groupModel.code > b.groupModel.code ? 1 : 0;
				});

				//멀티컴퍼니 최하단으로 수정
				items.sort((a, b) => {
					if (b.name == '멀티컴퍼니') {
						return -1;
					}
					return 0;
				});
			}

			let optionItems = [];
			let prevGroupModel = null;
			let sameGroupModelItems = [];
			items.forEach((optionItem) => {
				if (JSON.stringify(prevGroupModel) !== JSON.stringify(optionItem.groupModel)) {
					if (sameGroupModelItems.length > 0) {
						let data = {
							groupModel: prevGroupModel,
							items: sameGroupModelItems,
						};
						optionItems.push(data);
						sameGroupModelItems = [];
					}
					prevGroupModel = optionItem.groupModel;
				}
				optionItem['domainText'] = [];
				sameGroupModelItems.push(optionItem);
			});
			if (sameGroupModelItems.length > 0) {
				let data = {
					groupModel: prevGroupModel,
					items: sameGroupModelItems,
				};
				optionItems.push(data);
			}
			return optionItems;
		},
		removeAccountAddon(data) {
			return data.filter((o) => !this.isAccountAddonChannel(o));
		},
		getRequiredAddonChannel(data) {
			return data.filter((o) => this.isRequiredAddonChannel(o));
		},
		//디폴트 세팅된 상품을 선택함.
		addonDefaultOptionSelect() {
			const addonChannel = this.optionItemPerAddon.filter((o) => o.service.code.indexOf('ADDON') > -1);
			addonChannel.forEach((addon) => {
				addon.options.forEach((option) => {
					option.items.forEach((item) => {
						if (item.channelItemSalesSectionModel.defaultSelected && item.orderCount == 0) {
							this.upCntOptionItem(item, addon.service);
						}
					});
				});
			});
		},
		//상품관리에서 디폴트 체크되어있으면  disabled처리
		isItemDisable(item) {
			return item.channelItemSalesSectionModel.defaultSelected;
		},
		//기본 주문 갯수가 0개 이상이거나  상품관리에서 디폴트 체크된 상품이면 항상 체크되어야함.
		isItemChecked(item) {
			if (item.orderCount > 0 || item.channelItemSalesSectionModel.defaultSelected) {
				return true;
			}
			return false;
		},
		// 기본서비스 선택 시 귀속된 부가서비스 조회
		async getAddonChannelList(param) {
			this.addonChannelList = [];
			// 각 부가서비스 항목 별 선택 여부
			this.isSelected = {};
			try {
				let { data } = await this.$store.dispatch('GET_ADDONCHANNELLIST', param); // 부가서비스 리스트 조회
				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}

				//신규 서비스 신청페이지에서 경리회계 부가서비스 제거)
				//config/setting.xxx.js 에서 제어함
				if (window.__env.hideAccountAddon) {
					data = this.removeAccountAddon(data);
				}
				this.addonChannelList = data;
				await this.addonChannelList.reduce((previous, current) => {
					return previous.then(async () => {
						current['isSelected'] = false;
						this.$set(this.isSelected, current.code, false);
						if (this.prevAddonChannels.length > 0) {
							const findIdx = this.prevAddonChannels.findIndex((selectedAddon) => selectedAddon.code == current.code);
							if (findIdx > -1) {
								await this.selectAddonChannel(current);
							}
						}
					});
				}, Promise.resolve());

				//필수 부가서비스는 무조건 선택되도록 한다.
				const requiredAddonChannel = this.getRequiredAddonChannel(data);
				await requiredAddonChannel.forEach((item) => {
					if (!item.isSelected) {
						this.selectAddonChannel(item);
					}
				});
				//부가서비스에 필수 부가서비스만 존재할 경우, 부가서비스 영역을 가린다. (공유형만)
				this.hideAddonChannelPart(data);
			} catch (error) {
				console.log(error);
			}
		},
		hideAddonChannelPart(addonList) {
			// 조회된 부가서비스가 필수 부가서비스만 존재한다면, 화면 내 부가서비스 표기 여부를 false로 지정한다.
			if (addonList.length === this.getRequiredAddonChannel(addonList).length) {
				this.showAddonChannel = false;
			} else {
				this.showAddonChannel = true;
			}
		},
		// 부가서비스 선택 시, 선택 부가서비스에 귀속된 옵션 조회
		async getAddonChannelOptionItemList(param, service) {
			try {
				let { data } = await this.$store.dispatch('GET_ADDONCHANNEL_OPTIONITEM_LIST', param); // 부가서비스 옵션 리스트
				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}

				const seperatedOptions = await this.seperateByItemGroup(data);

				await seperatedOptions.reduce((previousOptionGroup, optionGroup) => {
					return previousOptionGroup.then(async () => {
						await optionGroup.items.reduce((previousKey, key) => {
							return previousKey.then(async () => {
								if (
									key.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity &&
									key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum > 0
								) {
									await this.setCntOptionItem(
										key,
										service,
										key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum,
									);
								} else {
									// 사용중 mapping
									key['orderCount'] = 0;
								}
							});
						}, Promise.resolve());
					});
				}, Promise.resolve());

				return seperatedOptions;
			} catch (error) {
				console.log(error);
			}
		},
		/*
		기본서비스 선택 시,
		1) store에 선택 기본서비스 등록/삭제
		2) 기본서비스 옵션 상품 리스트 조회
		3) 기본서비스 귀속 부가서비스 리스트 조회
		*/
		async selectBaseChannel(item) {
			if (this.isActive[item.id]) {
				//this.isActive[item.id] = false;
				return;
			}

			this.addonItemSelected = null;
			this.addonChannelList = [];
			this.baseChannelOptionItemList = [];
			this.optionItemPerAddon = [];

			for (let i in this.isActive) {
				this.$set(this.isActive, i, false);
			}
			this.$set(this.isActive, item.id, true);
			this.selectedServiceType = item.serviceCategoryModel.serviceType;
			this.basicItemSelected = this.cloneObj(item);
			this.usePeriod = this.basicItemSelected.usePeriod;
			this.userCnt = this.basicItemSelected.addUserIntervalPolicyModel.minimum;

			const { data } = await this.$store.dispatch('GET_END_DATE', {
				startDate: this.startDate,
				period: this.usePeriod,
			});
			this.endDate = data;

			await this.$store.commit('setEndDate', data);
			// 기본서비스 선택
			this.basicItemSelected = await this.setBaseInfo(this.basicItemSelected);
			this.basicItemSelected = await this.setMembershipFeeDiscountMatrix(this.basicItemSelected);
			this.basicItemSelected = await this.setDefaultFeeDiscountMatrix(this.basicItemSelected);
			this.basicItemSelected = await this.setAddUserDiscountMatrix(this.basicItemSelected);
			this.basicItemSelected = await this.setDiscountSum(this.basicItemSelected);

			this.$store.commit('clearApplyServiceInfo');
			// 1) store에 선택 기본서비스 등록
			this.$store.commit('setBasicItemSelected', this.basicItemSelected);
			// 2) 기본서비스 옵션 상품 리스트 조회
			// 해당 멤버십에서 운영중인 기본서비스 옵션 조회
			let optionData = await this.getBaseChannelOptionItemList(
				this.basicItemSelected.code,
				this.cloneObj(this.basicItemSelected),
			);
			if (optionData && optionData.length > 0) {
				let optionPerService = {
					service: this.cloneObj(this.basicItemSelected),
					options: optionData,
				};
				await this.optionItemPerAddon.push(optionPerService);
				// 기존 선택했던 옵션 상품 가져오기
				if (this.prevBaseChannel.length > 0) {
					if (this.prevBaseChannel[0].code == optionPerService.service.code) {
						if (this.prevBaseChannel[0].optionItemSelected.length > 0) {
							await optionData.forEach((optionGroup) => {
								optionGroup.items.forEach((item) => {
									const findIdx = this.prevBaseChannel[0].optionItemSelected.findIndex(
										(selectedOption) => selectedOption.code == item.code,
									);
									if (findIdx > -1) {
										const prevItem = this.prevBaseChannel[0].optionItemSelected[findIdx];
										this.setCntOptionItem(item, optionPerService.service, prevItem.orderCount);
										// 가상도메인 세팅
										if (item.itemType == 'VIRTUAL_DOMAIN') {
											for (let i = 0; i < prevItem.orderCount; i++) {
												item.domainText[i] = prevItem.domainText[i];
											}
										}
									}
								});
							});
						}
					}
				}
			}

			await this.getAddonChannelList(this.basicItemSelected.code);
			// 3) 기본서비스 귀속 부가서비스 리스트 조회
			await this.setTotQuota();
		},

		/*
		- 부가서비스 선택
		1) store에 선택 부가서비스 추가/삭제
		2) 부가서비스 옵션 상품 리스트 조회
		*/
		async selectAddonChannel(item) {
			if (item.isSelected) {
				this.addonItemSelected = null;
				item.isSelected = false;
			} else {
				if (this.isAccountAddonChannel(item)) {
					// 선택한게 회계서비스 이면
					await this.addonChannelList.reduce((previous, current) => {
						return previous.then(async () => {
							if (current.isSelected && this.isAccountAddonChannel(current) && current.code !== item.code) {
								await this.selectAddonChannel(current);
							}
						});
					}, Promise.resolve());
				}
				this.addonItemSelected = item;
				item.isSelected = true;
			}

			// 부가서비스 선택 시
			if (item.isSelected) {
				// 기본 정보 setting
				let addonItem = this.cloneObj(item);
				addonItem = await this.setBaseInfo(item);
				// 설치비 계산
				addonItem = await this.setMembershipFeeDiscountMatrix(item);
				// 기본료 계산
				addonItem = await this.setDefaultFeeDiscountMatrix(item);
				// 사용자 추가구간 계산
				addonItem = await this.setAddUserDiscountMatrix(item);
				// 할인 내역 계산
				addonItem = await this.setDiscountSum(item);

				// 무료체험일 경우
				if (item.freeTrialPolicyModel) {
					if (item.freeTrialPolicyModel.usePolicy) {
						const { data } = await this.$store.dispatch('GET_END_DATE', {
							startDate: this.startDate,
							period: item.freeTrialPolicyModel.usagePeriod,
						});
						addonItem['freeTrialExpiredDate'] = data;
					}
				}

				await this.$store.commit('addAddonChannelSelected', addonItem);

				// 부가서비스 옵션 상품 조회
				let optionData = await this.getAddonChannelOptionItemList(addonItem.code, this.cloneObj(addonItem));
				if (optionData && optionData.length > 0) {
					let optionPerService = {
						service: this.cloneObj(addonItem),
						options: optionData,
					};

					if (this.prevAddonChannels.length > 0) {
						const addonIdx = this.prevAddonChannels.findIndex((addon) => addon.code == addonItem.code);
						if (addonIdx > -1) {
							if (this.prevAddonChannels[addonIdx].optionItemSelected.length > 0) {
								await optionData.forEach((optionGroup) => {
									optionGroup.items.forEach((option) => {
										const selectedIdx = this.prevAddonChannels[addonIdx].optionItemSelected.findIndex(
											(obj) => obj.code == option.code,
										);

										if (selectedIdx > -1) {
											const prevItem = this.prevAddonChannels[addonIdx].optionItemSelected[selectedIdx];
											this.setCntOptionItem(option, addonItem, prevItem.orderCount);
										}
									});
								});
							}
						}
					}
					await this.optionItemPerAddon.push(optionPerService);
					this.addonDefaultOptionSelect();
				}
				// 부가서비스 옵션 조회
			} else {
				// 부가서비스 선택 해제 시
				let idx = this.optionItemPerAddon.findIndex((obj) => obj.service.code == item.code);
				if (idx > -1) {
					this.optionItemPerAddon.splice(idx, 1);
				}
				this.$store.commit('removeAddonChannelSelected', item.code);
			}
		},
		// 옵션 상품 수량 set (처음 open 시, minimum 값으로)
		async setCntOptionItem(item, dependonService, count) {
			// 단위 수량
			item.orderCount = count;
			if (dependonService.code.indexOf('ADDON-') > -1) {
				// 부가서비스
				item = await this.setInfo_ACO(
					item,
					dependonService.freeTrialPolicyModel.usePolicy,
					dependonService.freeTrialPolicyModel.usagePeriod,
				);
				this.$store.commit('addAddonOptionSelected', {
					addonCode: dependonService.code,
					data: item,
				});
			} else {
				// 기본서비스
				item = await this.setInfo_BCO(item);
				await this.$store.commit('addBaseOptionSelected', item);
				if (item.groupModel.name.indexOf('용량') > -1) {
					await this.setTotQuota();
				}
			}
		},
		// 옵션 상품 수량 추가
		async upCntOptionItem(item, dependonService) {
			// 단위 수량
			let cntUnit = this.unitCount(item);

			// 라디오 버튼 처리
			const serviceIdx = this.optionItemPerAddon.findIndex((obj) => obj.service.code == dependonService.code);
			if (item.groupModel.isGroupped) {
				if (item.groupModel.name.indexOf('용량') == -1 && item.groupModel.name.indexOf('가상') == -1) {
					if (item.groupModel.selectionType == 'RADIO') {
						const optionIdx = this.optionItemPerAddon[serviceIdx].options.findIndex(
							(option) => option.groupModel.code == item.groupModel.code,
						);
						await this.optionItemPerAddon[serviceIdx].options[optionIdx].items.reduce((previous, current) => {
							return previous.then(async () => {
								if (current.code !== item.code && current.orderCount > 0) {
									await this.downCntOptionItem(current, dependonService);
								}
							});
						}, Promise.resolve());
					}
				}
			}
			if (item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
				if (
					item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum > 0 &&
					item.orderCount + cntUnit > item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum
				) {
					this.$ms.dialog.alert(
						'최대 구매 수량 제한',
						item.name +
							'은 최대' +
							item.channelItemSalesSectionModel.channelItemSalesPolicyModel.maximum +
							'개 까지 구매 가능합니다.',
					);

					return;
				}
			}
			// type에 따른 데이터셋을 찾고, 그 안의 옵션상품 카운트를 증가
			item.orderCount = item.orderCount + cntUnit;
			if (dependonService.code.indexOf('ADDON-') > -1) {
				// 부가서비스
				item = await this.setInfo_ACO(
					item,
					dependonService.freeTrialPolicyModel.usePolicy,
					dependonService.freeTrialPolicyModel.usagePeriod,
				);
				this.$store.commit('addAddonOptionSelected', {
					addonCode: dependonService.code,
					data: item,
				});
			} else {
				// 기본서비스
				item = await this.setInfo_BCO(item);
				await this.$store.commit('addBaseOptionSelected', item);
				if (item.groupModel.name.indexOf('용량') > -1) {
					await this.setTotQuota();
				}
			}
		},
		// 기본서비스 옵션 상품 수량 취소
		// type : VIRTUAL_DOMAIN/ VOLUME/ ETC
		async downCntOptionItem(item, dependonService) {
			if (item.orderCount < 1) {
				return;
			}

			// 단위 수량
			let cntUnit = this.unitCount(item);

			if (item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
				if (
					item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum > 0 &&
					item.orderCount - cntUnit < item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum
				) {
					this.$ms.dialog.alert(
						'최소 구매 수량 필수',
						item.name +
							'은 최소' +
							item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum +
							'개 구매 하셔야 합니다.',
					);

					return;
				}
			}
			item.orderCount = item.orderCount - cntUnit;
			if (dependonService.code.indexOf('ADDON-') > -1) {
				// 부가서비스
				item = await this.setInfo_ACO(
					item,
					dependonService.freeTrialPolicyModel.usePolicy,
					dependonService.freeTrialPolicyModel.usagePeriod,
				);
				this.$store.commit('substractAddonOptionSelected', {
					addonCode: dependonService.code,
					data: item,
				});
			} else {
				// 기본서비스
				item = await this.setInfo_BCO(item);
				await this.$store.commit('substractBaseOptionSelected', item);
				if (item.groupModel.name.indexOf('용량') > -1) {
					await this.setTotQuota();
				}
			}
		},
		// 기본서비스 옵션 상품 금액 계산
		setInfo_BCO(item) {
			item['userCnt'] = this.userCnt;
			item['usePeriod'] = this.usePeriod;

			if (item.channelItemSalesSectionModel.billingType == 'MONTHLY') {
				// 월과금 = 가격 * 사용기간 * 개수
				item['finalPrice'] =
					parseInt(item.channelItemSalesSectionModel.price) * parseInt(this.usePeriod) * parseInt(item.orderCount);
			} else if (item.channelItemSalesSectionModel.billingType == 'ONCE') {
				// 1회성 = 가격 * 개수
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price) * parseInt(item.orderCount);
			} else if (item.channelItemSalesSectionModel.billingType == 'ESTIMATE') {
				//견적가 = 가격
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price);
			}
			return item;
		},
		setInfo_ACO(item, useFreeTrial, freeTrialPeriod) {
			item['userCnt'] = this.userCnt;
			item['usePeriod'] = this.usePeriod;
			if (item.channelItemSalesSectionModel.billingType == 'MONTHLY') {
				let payPeriod = parseInt(item.usePeriod);
				if (useFreeTrial) {
					payPeriod -= parseInt(freeTrialPeriod);
				}
				// 월과금
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price) * payPeriod * parseInt(item.orderCount);
			} else if (item.channelItemSalesSectionModel.billingType == 'ONCE') {
				// 1회성
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price) * parseInt(item.orderCount);
			} else if (item.channelItemSalesSectionModel.billingType == 'ESTIMATE') {
				//견적가
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price);
			} else {
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price);
			}

			if (useFreeTrial) {
				item['finalPriceReal'] = item.finalPrice;
				item.finalPrice = 0;
			} else {
				item['finalPriceReal'] = item.finalPrice;
			}

			return item;
		},
		// (기본/부가)서비스 객체 기본 속성들(사용기간, 사용자수, 추가사용자수) 설정
		setBaseInfo(item) {
			item['usePeriod'] = this.usePeriod;
			item['userCnt'] = this.userCnt;
			// 추가 사용자 수
			item['addUserCnt'] =
				item.userCnt - item.defaultFeePolicyModel.defaultUserNum > 0
					? item.userCnt - item.defaultFeePolicyModel.defaultUserNum
					: 0;
			// 장기선납 할인 목록 array
			if (item.periodPolicyModel.useLongTermPrepaymentDiscount) {
				item.periodPolicyModel['discountAmount'] = [];
			}
			return item;
		},
		handleDomainTextChange(item) {
			this.$store.commit('addBaseOptionSelected', item);
		},
		unitCount(item) {
			const unitCnt = 1;
			const dependsOnUserCount = item.channelItemSalesSectionModel.channelItemSalesPolicyModel.dependsOnUserCount; // 기본서비스 수대로 과금
			const useOrderQuantity = item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity; // 주문수량 설정
			const isCharge = !item.channelItemSalesSectionModel.noCharge; // 유료상품여부

			if (dependsOnUserCount && isCharge) {
				return this.userCnt;
			} else if (useOrderQuantity) {
				return item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit;
			}
			return unitCnt;
		},
		isChangeOrderCount(item) {
			return (
				(item.orderCount > 0 || item.channelItemSalesSectionModel.defaultSelected) &&
				item.channelItemSalesSectionModel.channelItemSalesPolicyModel.dependsOnUserCount &&
				!item.channelItemSalesSectionModel.noCharge
			);
		},
	},
	components: {
		SideBar,
	},
};
</script>
<style scoped>
.tool_tip {
	z-index: 100;
}
.billing_type {
	margin-left: -10px;
}
</style>
