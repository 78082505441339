<template>
	<div class="com_layer_untitle layer_price_detail" style="width: 560px; height: 600px">
		<div class="lay_header">
			<a class="btn_close" title="닫기"><span class="ic_set ic_close" v-on:click="closeModal()"></span></a>
		</div>
		<!--content-->
		<div class="lay_body">
			<h2>{{ item.name }} - 사용자 금액 상세</h2>
			<table class="com_tb_metadata tb_order_total" v-if="item.addUserTotDiscount < 0">
				<tbody>
					<tr>
						<td class="tit"><span class="txt">할인 전 금액</span></td>
						<td class="data">
							<span class="txt line_through">{{ item.addUserTotNormalPrice | currency }}원</span>
						</td>
					</tr>
					<tr class="">
						<td class="tit">
							<span class="txt">할인 금액</span>
						</td>
						<td class="data">
							<span class="txt">{{ item.addUserTotDiscount | currency }}원</span>
						</td>
					</tr>
					<tr class="total">
						<td class="tit"><span class="txt">할인 후 금액</span></td>
						<td class="data emphasise">
							<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="com_tb_metadata tb_order_total" v-if="item.addUserTotDiscount >= 0">
				<tbody>
					<tr class="total">
						<td class="tit"><span class="txt">결제 금액</span></td>
						<td class="data emphasise">
							<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
						</td>
					</tr>
				</tbody>
			</table>

			<h2>할인 구간</h2>
			<div class="com_list">
				<table class="com_tb_data tb_sale_section">
					<thead>
						<tr>
							<th class="subject"><span class="txt">구간(명)</span></th>
							<th class="num"><span class="txt">월 요금(원)</span></th>
							<th class="num"><span class="txt">수량(명)</span></th>
							<th class="detail flex" v-if="item.addUserTotDiscount < 0">
								<span class="txt">할인</span>
							</th>
							<th class="num" v-if="item.addUserTotDiscount < 0">
								<span class="txt">할인 금액(원)</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in item.addUserDiscountMatrix" :key="index">
							<td class="subject">
								<span class="txt">{{ row.interval }}</span>
							</td>
							<td class="num">
								<span class="txt">{{ row.price | currency }}</span>
							</td>
							<td class="num">
								<span class="txt">{{ row.discountCnt }}</span>
							</td>
							<td
								class="detail flex"
								v-if="index === 0 && item.addUserTotDiscount < 0"
								:rowspan="item.addUserDiscountMatrix.length"
								v-html="row.discountInfo"
							></td>
							<td class="num" v-if="item.addUserTotDiscount < 0">
								<span class="txt">{{ row.discountTotPrice | currency }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mounted() {},
	methods: {
		closeModal() {
			this.$emit('close');
		},
	},
};
</script>

<style></style>
